<template>
  <v-main>
    <v-container
      fill-height
      fluid>
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm8
          md4>
          <v-card
            v-if="!emailsent"
            color="biscuit lighten-2"
            class="elevation-12">
            <v-toolbar
              color="biscuit"
              class="justify-center">
              <v-spacer />
              <v-toolbar-title>Request Reset Password</v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- <v-card-title class="mb-2 justify-center" style="background-color: #d9c7A3">
            <v-spacer />
            Admin Panel
            <v-spacer /> 
            </v-card-title> -->

            <v-card-text>
              <v-form 
                v-model="valid"
                @submit.prevent="handleSubmit(save)"
                lazy-validation
                ref="requestform"
              >
                <v-text-field
                  ref="email"
                  v-model="email"
                  prepend-icon="mdi-account"
                  label="Email"
                  placeholder="user@address.com"
                  required
                  :rules="emailRules"
                />
                
                
              </v-form>
            </v-card-text>
            <!-- <v-divider class="mt-5"/> -->
              <!-- <v-card-text class="align-right justify-right text-caption">
                <p>Forgot password?</p>
              </v-card-text> -->
            <v-divider class="mt-5"/>
            <v-card-actions>
              <v-btn
                @click="reset"
                color="biscuit"
                >Reset
              </v-btn>
              <v-spacer/>
              <v-btn
                :disabled="!valid"
                align-center
                justify-center
                color="guacamole"
                @click="submit">Submit
                <v-progress-circular indeterminate color="biscuit" v-if="loading" />
              </v-btn>
              
            </v-card-actions>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :top="true"
            >
              {{ errorMessages }}
              <v-btn
                dark
                text
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </v-snackbar>
          </v-card>
          <v-card v-else>
            <v-card-text class="text-center">
              <p>An email has been sent to: {{ email }} with instructions on how to reset
              the password.</p>
              <p>The email might take a few minutes to arrive, please be patient and also
                consider checking your spam folder.</p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
  </v-main>
</template>

<script>
import User from '../models/user';

export default {
  data: function () {
    return {
      valid: true,
      emailsent: false,
      email: '',
      user: new User('', ''),
      errorMessages: 'Incorrect login info',
      snackbar: false,
      color: 'general',
      showPassword: false,
      loading: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    }
  },

  computed: {
    loggedIn() {
      //return this.$store.state.auth.status.loggedIn;
      //return this.$store.getters['auth/authorized'] // FIXME
      return this.$store.state.newauth.isLoggedIn
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({name: 'Dashboard View'})
    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    submit () {
      if (this.$refs.requestform.validate())
        this.$store.dispatch('newauth/passwordRecovery', this.email)
        
    },
    reset () {
      this.email = "";
      this.$refs.requestform.reset()
    }
    // LOGIN METHOD using the old authservice.
    // login: function () {
    //   this.loading = true
    //   // let username = this.username
    //   // let password = this.password
    //   this.user.username = this.username
    //   this.user.password = this.password
    //   // this.$store.dispatch('auth/login', { username, password })
    //   this.$store.dispatch('auth/login', this.user)
    //     .then(() => {
    //       this.loading = false
    //       this.$router.push('/dashboard')
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.snackbar = true
    //       this.loading = false
    //     }
    //     )
    // }
  },
  metaInfo () {
    return {
      title: 'Super Secret | Login'
    }
  }
}
</script>
